<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('orderAlertLogFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="alertLogs"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openDetailModal"
            v-on:refresh="filterAlertLogs(filterParams)"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton color="info" square size="sm" @click="openModalEvent('list', 'orderAlertLogDetail', item, 'ID: '+item.id)">
                  Detay
                </CButton>
              </td>
            </template>
            <template #createTime="{item}">
              <td> {{ dateFormat(item.createTime) }}</td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ListModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterAlertLogs"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import moment from 'moment';
import FilterSidebar from '../components/filterSidebar'
import ListModal from '../components/listModal'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'AlertLogs',
  components: {FilterSidebar, ListModal},
  computed: {
    loading: function(){
      return this.$store.getters.orderLoading
    },
    alertLogs: function(){
      return this.$store.getters.orderAlertLogs
    },
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderAlertLogDetail',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderAlertLogFilter',
      filterParams: {},
      lastItem: {},
      activePage: 1,
      itemsPerPage: 10,
      fields: [
        {key: 'id', label: 'ID',_style: 'font-size:12px'},
        {key: 'createTime', label: 'Tarih',_style: 'font-size:12px'},
        {key: 'log', label: 'LOG',_style: 'font-size:12px'},
        {key: 'level', label: 'Seviye',_style: 'font-size:12px'},
        {key: 'alerted', label: 'Alarm',_style: 'font-size:12px'},
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        }
      ],
     
      filters: [{
        type: 'numeric',
        dataIndex: 'id',
        field: 'id',
        comparison: 'eq'
      }, {
        type: 'integer',
        dataIndex: 'level',
        field: 'level',
        comparison: 'eq'
      }, {
        type: 'string',
        dataIndex: 'log',
        field: 'log',
        comparison: 'eq'
      }, {
        type: 'integer',
        dataIndex: 'alerted',
        field: 'alerted',
        comparison: 'eq'
      },{
          type: 'date',
          dataIndex: 'startTime',
          field: 'createTime',
          comparison: 'gt'
        },
        {
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'lt'
        }
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item, index) {
      this.lastItem = item;
    },

    openDetailModal(item) {
      this.detailModal = true;
      this.selectedLog = item;
    },
    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    async filterAlertLogs(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('order_alertLogs', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterAlertLogs(this.filterParams)
  }
}
</script>
